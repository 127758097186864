code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Polaris overrides */
/* Polaris is brilliant enough to add a ThemeProvider and not let us overwrite it on embedded apps... */
#root, #root > div:first-of-type {
  height: inherit;
  min-height: 100%;
}

/* .Polaris-Page__Content {
  position: relative;
} */

.Polaris-Tabs {
  /* height: 86px; */
  justify-content: flex-end;
  border-bottom: none !important;
}

.thumbnail-container {
  position: relative;
  width: 100%
}

.thumbnail-container:hover .thumbnail-container {
  opacity: 0.3;
}

.thumbnail-container:hover .thumbnail-overlay {
  opacity: 1;
}

.thumbnail-container .thumbnail-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .3s ease;
  background-color: rgba(0, 0, 0, .6);
  z-index: 100;
}

thumbnail-container .Polaris-Thumbnail__Image {
  display: block;
  width: 100%;
  height: auto;
}